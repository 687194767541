import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import loginImage from "images/login.jpg";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { Alert, Button, Form, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import UserAPI from "service/user_api";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-24 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

// const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-green-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Verify Your Account",
  // socialButtons = [
  //   {
  //     iconImageSrc: googleIconImageSrc,
  //     text: "Sign In With Google",
  //     url: "https://google.com"
  //   },
  // ],
  submitButtonText = "Verify",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "#",
  signupUrl = "#",
}) => {
  const [code, setCode] = useState();
  const [email, setEmail] = useState();
  const param = useParams();
  const [hidden, setHidden] = useState(true);
  const [error, setError] = useState();
  const navigate = useHistory();
  const request = localStorage.getItem("volunteer");
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: <h1 className="font-semibold">Verify success</h1>,
    });
  };
  useEffect(() => {
    const getUser = async () => {
      const response = await UserAPI.getUser(param.idUser);
      setEmail(response.email);
    };
    const sendCode = async () => {
      await UserAPI.resendMail(param.idUser);
    };
    sendCode();
    getUser();
  }, []);
  const verify = async () => {
    if (request === "true") {
      try {
        await UserAPI.verifyUser(param.idUser, code);
        openNotificationWithIcon("success");
        localStorage.removeItem("volunteer");
        navigate.push(`/get-involved`);
      } catch (error) {
        setError(error.response.data.message);
        setHidden(false);
      }
    } else {
      try {
        await UserAPI.verifyUser(param.idUser, code);
        openNotificationWithIcon("success");
        navigate.push(`/login`);
      } catch (error) {
        setError(error.response.data.message);
        setHidden(false);
      }
    }
  };
  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href="/home">
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <SocialButtonsContainer>
                  <h1 className="text-center mb-8">
                    {" "}
                    We have emailed a 6-digit confirmation code to{" "}
                    <p className="font-bold">{email}</p>Please enter the code in
                    below box to verify your email.
                  </h1>
                </SocialButtonsContainer>
                {hidden === true ? null : (
                  <div className="mb-8">
                    <Alert type="error" message={error} banner />
                  </div>
                )}
                {/* <Form onSubmit={verify}>
                  <Input
                    type="code"
                    placeholder="Verify Code"
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <Button
                  type="submit"
                    onClick={verify}
                    className="mt-5 w-full"
                    style={{
                      background: "#035C12",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Sign Up
                  </Button>
             
                </Form> */}
                <Form onFinish={verify}>
                  <Form.Item name="code">
                    <Input
                      type="number"
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="submit"
                      onClick={verify}
                      className="mt-5 w-full"
                      style={{
                        background: "#035C12",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Sign Up
                    </Button>
                  </Form.Item>
                </Form>
                <p tw="mt-8 text-sm text-gray-600 text-center">
                  Already have an account?{" "}
                  <a href="/login" tw="border-b border-gray-500 border-dotted">
                    Log in
                  </a>
                </p>
              </FormContainer>
            </MainContent>
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
