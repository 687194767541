import React, { useContext, useState } from "react";
import tw from "twin.macro"; //eslint-disable-line

import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import HeadNav from "../../components/hero/HeadNav";

import FootNav from "../../components/footers/FootNav";
import { ContentWithPaddingXl } from "../../components/misc/Layouts";
import { SectionHeading } from "../../components/misc/Headings";
import TitleNav from "../../components/hero/TitleNav";
import { Context } from "components/Wrapper";
import CommingSoon from "pages/result/CommingSoon";
import DonateFormV2 from "./donate-form-v2";
import DonateInfoPC from "./donate-info-pc";
import DonateInfoMobile from "./donate-info-mobile";

const Heading = tw(SectionHeading)`text-5xl font-bold text-gray-900  `;
const HeadingRow = tw.div`flex justify-center`;

export default () => {
  const context = useContext(Context);
  const language = context.locale;
  const [option, setOption] = useState("als");
  const [checkout, setCheckout] = useState(false);

  const selectOption = (e) => {
    setOption(e.target.value);
  };
  return (
    <div className="font-[Montserrat]">
      <AnimationRevealPage>
        <HeadNav />
        {context.locale === "en" ? (
          <TitleNav pageTitleString="Donation" />
        ) : (
          <TitleNav pageTitleString="Đóng góp" />
        )}

        <ContentWithPaddingXl className="mt-8 grid justify-items-center">
         
        <div className="hidden lg:block"><DonateInfoPC/></div>
         <div className="block  lg:hidden"><DonateInfoMobile/></div>
        </ContentWithPaddingXl>
      

        <div className="-mx-8 -mb-8">
          <FootNav />
        </div>
      </AnimationRevealPage>
    </div>
  );
};
