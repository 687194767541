import { Card } from "antd";
import React, { useContext } from "react";

import { SectionHeading } from "components/misc/Headings";
import tw from "twin.macro";
import styled from "styled-components";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import Becomeasprout from "../../images/Become-a-sprout.jpg";
import Becomeasapling from "../../images/Become-a-sapling.jpg";
import { Context } from "../../components/Wrapper";


const HeadingRow = tw.div`flex justify-center`;
const Heading = tw(SectionHeading)`text-xl font-bold text-primary-600`;
const Text = styled.div`
  ${tw`text-base  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-lg font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 `}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default function BecomingASeedling() {
  const context = useContext(Context);
  return (
    <Container className="mt-4 ">
      <ContentWithPaddingXl>

        {/* <Card
          className="shadow-inner text-center"
          style={{
            backgroundColor: "#6abf4b"
          }}
        >{context.locale === "en" ?
          <h1 className="lg:text-[1.5rem] text-[0.75rem] text-white">
            BECOMING A{" "}
            <strong className="lg:text-[3rem] text-[2rem]">Seedling</strong>
          </h1> : <h1 className="lg:text-[1.5rem] text-[0.75rem] text-white">
           
            <strong className="lg:text-[3rem] text-[2rem]">Triển khai hoạt động</strong>
          </h1>}
        </Card> */}

        <div className="mt-[-1.75rem] ml-8">

          {context.locale === "en" ?
            <Text className="grid grid-cols-5 ">
              <div className="col-span-5 lg:col-span-3">
                <Heading >
                Implementers
                </Heading>
                <li className="leading-loose">
                Becoming a Volunteer:  to participate in content development, compile and translate original articles related to ALS, support media and communication, etc.
                </li>
              </div>
              <div className="col-span-5 lg:col-span-2 py-8 px-8 flex justify-end">
                <img src={Becomeasprout} className="h-[15rem] w-[15rem]" />
              </div>
             <div className="col-span-5 lg:col-span-3">
             <Heading>
             Supporters
              </Heading>
              <li className="leading-loose">
              Becoming a Supporter: to join us or create your own campaign to raise funds for ALS Vietnam to maintain operations, fund medical research, support ALS patients, etc.
              </li>
             </div>
              <div className="col-span-5 lg:col-span-2 py-8 px-8 flex justify-end">
                <img src={Becomeasapling} className="h-[15rem] w-[15rem]" />
              </div>
            </Text> :
            <Text className="grid grid-cols-5">
              <div className="col-span-5 lg:col-span-3 mt-[-0.5rem]">
              <Heading >
             Triển khai hoạt động
              </Heading>
              <li className="leading-loose">
              Trở thành Tình nguyện viên: tham gia phát triển nội dung, biên phiên dịch các bài viết gốc liên quan đến ALS, hỗ trợ truyền thông
              </li>
              </div>
              <div className="col-span-5 lg:col-span-2 py-8 px-8 flex justify-end">
                <img src={Becomeasprout} className="h-[15rem] w-[15rem]" />
              </div>
              <div className="col-span-5 lg:col-span-3 mt-[-2rem]">
              <Heading>
                Gây quỹ
              </Heading>
              <li className="leading-loose">
              Trở thành Người ủng hộ: cùng chúng tôi hoặc tạo chiến dịch của riêng bạn gây quỹ cho ALS Vietnam để duy trì vận hành, đóng góp quỹ quốc tế công tác nghiên cứu bệnh ALS              </li>
            </div>
              <div className="col-span-5 lg:col-span-2 py-8 px-8 flex justify-end mt-[-2rem]">
                <img src={Becomeasapling} className="h-[15rem] w-[15rem]" />
              </div>
            </Text>}
        </div>
        {/* <div className="col-span-4 lg:col-span-1 py-8 px-8">
            <img src={Becomeasprout} />
          </div> */}

      </ContentWithPaddingXl>
    </Container>
  );
}
