import React, { useContext } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { Context } from "../../components/Wrapper";
import Becomeaseed from "../../images/Become-a-seed.jpg";
import { Card } from "antd";
const HeadingRow = tw.div`flex justify-center`;
const Heading = tw(SectionHeading)`text-2xl font-bold text-primary-600`;
const Text = styled.div`
  ${tw`text-base  text-gray-800`}
  p {
    ${tw`mt-2 text-base leading-loose`}
  }
  h1 {
    ${tw`text-2xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-lg font-bold mt-2`}
  }
  h3 {
    ${tw`text-lg font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 `}
      p {
        ${tw`mt-0 inline leading-loose`}
      }
    }
  }
`;
export default () => {
  const context = useContext(Context);

  return (
    <Container >
      <ContentWithPaddingXl>
        {/* <div
        className="-mt-[6rem] px-8"
          style={{
            position: "relative",
            fontFamily: "Montserrat",
          }}
        >{context.locale === "en"?
          <Text>
            <Heading><li>Becoming a Member</li></Heading>

            <p>
              to partake in planning and operating activities of the
              organization and related projects.
            </p>

            <Heading><li>Becoming an Advisory Board Member</li></Heading>

            <p>
              to partake in medical professional consultation, coordinate to
              organize periodic online medical seminars to update information
              for patients and doctors in related fields, support media and
              communication, interpret, apply information technology/artificial
              intelligence to support patient communication (e.g voice storage,
              eye gaze technology, etc).
            </p>
          </Text>: <Text>
              <Heading><li>Trở thành Thành viên</li></Heading>

              <p>
                  tham gia xây dựng kế hoạch, vận hành các hoạt động của tổ chức và các dự án liên quan
              </p>

              <Heading><li>Trở thành Ban Cố Vấn</li></Heading>

              <p>
                  tham gia cố vấn chuyên môn y khoa, phối hợp tổ chức định kỳ hội thảo Y khoa trực tuyến để cập nhật thông tin cho bệnh nhân và y bác sĩ các ngành liên quan, hỗ trợ truyền thông, biên dịch, ứng dụng công nghệ thông tin/trí tuệ nhân tạo nhằm hỗ trợ bệnh nhân giao tiếp (lưu trữ giọng nói, giao tiếp bằng liếc mắt ...) …
              </p>
            </Text>}
        </div> */}
        {/* <Card
          className="shadow-inner text-center"
          style={{
            backgroundColor: "#6abf4b"
          }}
        >{context.locale === "en"?
          <h1 className="lg:text-[1.5rem] text-[0.75rem] text-white">
            BECOMING A{" "}
            <strong className="lg:text-[3rem] text-[2rem]">Seed</strong>
          </h1>:<h1 className="lg:text-[1.5rem] text-[0.75rem] text-white">
          
           <strong className="lg:text-[3rem] text-[2rem]">Xây dựng kế hoạch</strong>
         </h1>}
        </Card> */}

        <div className="grid grid-cols-5 ml-8">
          <div className="col-span-5 lg:col-span-5">
            {context.locale === "en" ? <Text>
              <Heading>
              Planners
              </Heading>
              <li className="mb-[0.5em]">
              Becoming a Member: to partake in planning and operating activities of the organization.
              </li>
              {/* <Heading>
                <li>Becoming an Advisory Board Member</li>
              </Heading> */}
             <li className="leading-loose">
              Becoming an Advisory Board Member: to partake in medical professional consultation, support media and communication, interpret, apply information technology/artificial intelligence to support patient communication.
              </li>
            </Text> : <Text>
              <Heading>
                Xây dựng kế hoạch
              </Heading>
              <li className="mb-[0.5em]">
                Trở thành Thành viên:  tham gia xây dựng kế hoạch, vận hành các hoạt động của dự án
              </li>

              <li className="leading-loose">
                Trở thành Ban cố vấn: tham gia cố vấn chuyên môn y khoa, truyền thông, biên dịch, ứng dụng công nghệ thông tin/trí tuệ nhân tạo nhằm hỗ trợ bệnh nhân giao tiếp            </li>
            </Text>}
          </div>
          {/* <div className="col-span-4 lg:col-span-2 py-8 px-8 flex justify-end">
            <img src={Becomeaseed} className="h-[15rem] w-[15rem]"/>
          </div> */}
        </div>
      </ContentWithPaddingXl>
    </Container>
  );
};
