import React, { useContext } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { Context } from "../../components/Wrapper";

const HeadingRow = tw.div`flex justify-center`;
const Heading = tw(
  SectionHeading
)`text-3xl md:text-5xl font-semibold mt-10 text-gray-900  `;
const Text = styled.div`
  ${tw`text-base leading-loose  text-gray-800`}
  p {
    ${tw`mt-10 text-base leading-loose`}
  }
  h1 {
    ${tw`text-2xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-xl md:text-3xl font-semibold mt-8`}
  }
  h3 {
    ${tw`text-lg font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw``}
      p {
        ${tw`mt-0 inline leading-loose`}
      }
    }
  }
`;
export default ({ headingText = "Common Goals:" }) => {
  const context = useContext(Context);

  return (
    <Container>
      <ContentWithPaddingXl >
        <div
          style={{
            position: "relative",
            fontFamily: "Montserrat",
          }}
        >
          {context.locale === "en" ?
            <Text>
              <p className="leading-loose">
              From the earliest days of ALS Vietnam, we have held the belief that "Helping others is a part of life." Despite considering ourselves fortunate to be in a position to provide assistance, we also recognize that assisting others in the battle against ALS is not simple. To work together for a common goal, to tackle challenges as a team, and to act morally requires the fortitude and endurance of many individuals, not just one. 
              </p>

              <Heading>{headingText}</Heading>

              <ul>
                <li>
                  <p>Raising community awareness of illness prevention</p>
                </li>
                <li>
                  <p>
                  Providing patients with ALS with helpful information and help them acquire a more optimistic outlook on the healing process
                  </p>
                </li>
                {/* <li>
                  Establishing connections among the medical community to change
                  the perception and traditional treatment of ALS and other
                  neurodegenerative diseases.
                </li> */}
              </ul>

              <div className="leading-loose mt-12 ">
              ALS Vietnam wishes to develop sustainably based on the synergy of individuals and organizations to bring hope and practical value for the patient. Join us in the following ways:
              </div>


            </Text> :
            <Text>
              <p className="leading-loose">
              Ngay từ những ngày đầu của ALS Vietnam, chúng tôi tin rằng “Một phần của việc sống là biết giúp đỡ người khác”. Chúng tôi tự nhận thấy mình may mắn như thế nào khi được ở cương vị trao đi sự giúp đỡ, nhưng chúng tôi cũng hiểu việc giúp đỡ người khác trong cuộc chiến chống lại bệnh ALS không hề đơn giản. Nó đòi hỏi lòng dũng cảm và sự kiên nhẫn của không chỉ một mà rất nhiều người đoàn kết vì một mục tiêu chung, cùng nhau đối mặt với khó khăn và làm điều đúng đắn.
              </p>

              <Heading>Mục tiêu chung</Heading>

              <ul>
                <li>
                  <p>Nâng cao kiến thức cộng đồng để phòng bệnh</p>
                </li>
                <li>
                  <p>
                    Giúp bệnh nhân có thêm thông tin và lạc quan trong hành trình chữa lành
                  </p>
                </li>
              </ul>

              <div className="leading-loose mt-12 ">
              ALS Vietnam mong muốn phát triển bền vững dựa vào sức mạnh tổng hợp của các cá nhân và tổ chức tình nguyện nhằm mang lại hy vọng và giá trị thiết thực cho bệnh nhân. Hãy tham gia cùng chúng tôi theo các cách sau:
              </div>


            </Text>}
        </div>
      </ContentWithPaddingXl>
    </Container>
  );
};
