import React, { useContext, useState } from "react";
import tw from "twin.macro";
import BackgroundDonate from "../../images/bg-donate.jpg";
import { Button, Form, Input, Modal } from "antd";
import { NumericFormat } from "react-number-format";
import { Context } from "components/Wrapper";
import { FormattedMessage } from "react-intl";
import DonateAPI from "service/donate_api";
import Donor from "./donor";
const Title = tw.h5`text-2xl font-bold text-gray-900`;
export default function DonateFormV2() {
  const context = useContext(Context);
  const [amount, setAmount] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const onSubmit = async () => {
    if (Number(amount) < 10000) {
      warning();
    } else {
      const params = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        amount: Number(amount),
        phone: phone,
        paymentGateway: "VNPAY",
        donationType: "GENERAL",
        donationCampaignId: "",
        generalFund: Number(amount),
        operateFund: 0,
        researchFund: 0,
        patientSupportFund: 0,
        hiddenInfo: false,
      };
      const response = await DonateAPI.createDonate(params);
      window.location.href = response.payment_link;
    }
  };
  const warning = () => {
    Modal.warning({
      title: `${
        context.locale === "en"
          ? "Please input amount is more than 10,000 VNĐ"
          : "Vui lòng nhập số tiền lớn hơn 10,000 VNĐ"
      }`,
    });
  };
  return (
  <div>  <div
  className="  justify-center items-center   bg-cover bg-no-repeat bg-center h-[32rem] w-screen rounded-sm"
  style={{ backgroundImage: `url(${BackgroundDonate})` }}
>
  <div className="w-full h-full flex  justify-center items-center bg-gray-500/30 backdrop-brightness-85">
    <div class="p-12 bg-white bg-opacity-40 backdrop-blur-lg rounded drop-shadow-lg">
      <Title>
        Chung tay cùng Quỹ Đồng Hành Cùng Bệnh Nhân ALS Việt Nam
      </Title>
      <div className="mt-16">
        {" "}
        <Form layout="horizontal">
          <Form.Item
            label={
              <div className="font-semibold">
                {context.locale === "en" ? "Amount" : "Nhập số tiền"}
              </div>
            }
            rules={[{ required: true, message: "Vui lòng nhập số tiền" }]}
          >
            <NumericFormat
              allowNegative={false}
              value={amount}
              onValueChange={(e) => setAmount(e.value)}
              customInput={Input}
              thousandSeparator=","
              addonAfter="VND"
            />
          </Form.Item>
          <h3 className="text-lg font-semibold">
            {" "}
            <FormattedMessage id="donate.titleInfo" />
          </h3>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-2">
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input your firstname!",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder={
                    context.locale === "en"
                      ? "Please input your firstname..."
                      : "Vui lòng nhập họ..."
                  }
                />
              </Form.Item>
            </div>
            <div className="col-span-2">
              {" "}
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input your lastname!",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder={
                    context.locale === "en"
                      ? "Please input your lastname..."
                      : "Vui lòng nhập tên..."
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-2">
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Please input valid email type!",
                  },
                  {
                    required: true,
                    message: "Please input your email",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={
                    context.locale === "en"
                      ? "Please input your email..."
                      : "Vui lòng nhập email..."
                  }
                />
              </Form.Item>
            </div>
            <div className="col-span-2">
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    max: 10,
                    message: "Please input valid phone type",
                  },
                ]}
              >
                <Input
                  addonBefore={"+84"}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder={
                    context.locale === "en"
                      ? "Please input your phone number..."
                      : "Vui lòng nhập số điện thoại..."
                  }
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <Button
              htmlType="submit"
              style={{
                background: "green",
                color: "white",
                float: "right",
                height: "3rem",
                borderRadius: "0.5rem",
              }}
              onClick={onSubmit}
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  </div>

  
</div>



<Donor/>
</div>
  );
}
