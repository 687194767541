
import { Context } from 'components/Wrapper'
import React, { useContext } from 'react'
import tw from 'twin.macro'
import qrCode from "../../images/qrcode-ntlt.jpg"
export default function DonateInfoPC() {
  const contentVN = "Cảm ơn bạn đã mong muốn hỗ trợ. Khoản quyên góp của bạn sẽ giúp bệnh nhân, nhân viên y tế và cộng đồng người Việt sinh sống ở Việt Nam và nước ngoài có thể tiếp cận thông tin tiếng Việt về căn bệnh, đồng thời hỗ trợ các nghiên cứu để tìm ra cách chữa trị. Mọi đóng góp đều được trân trọng dù lớn hay nhỏ, đều mang giá trị thiết thực đến cho những người bị ảnh hưởng bởi ALS."
  const contentEN = "We extend our heartfelt gratitude for your readiness to contribute. Your generosity will provide vital support to patients, medical professionals, and the Vietnamese community both within Vietnam and overseas, by facilitating access to information about the disease in the Vietnamese language. It will also bolster ongoing research efforts aimed at discovering effective treatments. We deeply value every contribution, irrespective of its size, as each donation is instrumental in delivering tangible aid to individuals impacted by ALS."
  const context = useContext(Context)
  const Label = tw.div`text-base text-[#636363]`
  const Row = tw.div`grid mt-1`
  const Content = tw.div`text-base font-bold`

  return (
   <div className='max-w-[1240px]  w-full'>
     <p className='text-base leading-loose mt-2'>{context.locale === 'en' ? contentEN : contentVN}</p>
    <div className=' rounded-lg bg-[#78C14E]/[0.2] p-8 mt-[3em]'>
     
      <div className='grid grid-cols-6 gap-8 '>
        <div className='col-span-4 rounded-lg p-8 bg-[#fff]/[0.8]'>
          {context.locale === "en" ?
            (<div className='en'>
               <h5 className='font-bold text-xl uppercase'>Donation information</h5>
              <div className='info grid gap-4'>
                <Row>
                  <Label>Beneficiary Bank:</Label>
                  <Content>Ngân hàng Thương mại cổ phần Kỹ Thương Việt Nam (Techcombank)</Content>
                </Row>
                <Row>
                  <Label>Account Number:</Label>
                  <Content>8020011967</Content>
                </Row>
                <Row>
                  <Label>Account Owner (Fund Representative):</Label>
                  <Content>NGUYEN THI LUU TRUYEN</Content>
                </Row>
                <Row>
                  <Label>Transfer Content:</Label>
                  <div className='text-base font-bold w-fit bg-[#F7FC16]/[0.6]'>Họ tên - Ủng hộ Quỹ ALS Vietnam</div>
                </Row>
              </div>
            </div>) :
            (<div className='vn'>
              <h5 className='font-bold text-xl uppercase'>Thông tin tài khoản thụ hưởng</h5>
              <div className='info grid gap-4'>
                <Row>
                  <Label>Ngân hàng thụ hưởng:</Label>
                  <Content>Ngân hàng Thương mại cổ phần Kỹ Thương Việt Nam (Techcombank)</Content>
                </Row>
                <Row>
                  <Label>Số tài khoản:</Label>
                  <Content>8020011967</Content>
                </Row>
                <Row>
                  <Label>Chủ tài khoản (Người đại diện nhận quỹ):</Label>
                  <Content>NGUYEN THI LUU TRUYEN</Content>
                </Row>
                <Row>
                  <Label>Nội dung chuyển khoản:</Label>
                  <div className='text-base font-bold w-fit bg-[#F7FC16]/[0.6]'>Họ tên - Ủng hộ Quỹ ALS Vietnam</div>
                </Row>
              </div>
            </div>)}
        </div>
        <div className='col-span-2 grid justify-items-center items-center gap-2'>
         <img src={qrCode} className='p-2 bg-[#fff] rounded-xl w-56 h-56'/>
          <span className='px-14 text-center text-sm -mt-6 italic'>{context.locale === 'en' ? 'Use the QR code scanner banking app to transfer money':'Dùng ứng dụng ngân hàng quét mã QR để chuyển khoản'}</span>
        </div>
      </div>
    </div>
   </div>
  )
}

