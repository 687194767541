import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { FormattedMessage } from "react-intl";
import ThaoStory from "../../images/Thao.png";
import { Button } from "antd";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-2 items-center`;
const Column = tw.div`w-full`;
const ImageColumn = tw(Column)`md:w-1/6 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-5/6 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div` text-left`;
const Subheadingcard = tw(SectionHeading)`text-xl lg:text-2xl font-bold `;

const Subheading = tw.p`text-left relative inline-block   `;
const Heading = tw(
  SectionHeading
)`mt-4 text-left  text-xl md:text-3xl font-semibold relative `;
const Description = tw.p`mt-4 text-left md:text-left text-base  font-normal leading-loose text-justify`;
const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-8 md:mt-16 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`,
  props.show
    ? tw`inline` : tw`hidden`,
]);

export default ({
  subheading = "Our Expertise",

  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "/healing_stories/bb19e30b-9145-42dc-9b6e-5fb906743ed1/thao-s-story",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = false,
  showButton = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            style={{ width: "200px", borderRadius: "50%" }}
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn >
          <TextContent>
            <Heading >{subheading}</Heading>
            <Description >{description}</Description>
            <div
            >
              <div className="mt-4 ">
                <PrimaryButton

                  buttonRounded={buttonRounded}
                  as="a"
                  href={primaryButtonUrl}
                  show={showButton}
                >
                  <FormattedMessage
                    id="button.readMore"
                    defaultMessage="Xem thêm"
                  />
                </PrimaryButton>
              </div>


            </div>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
