import React, { useContext } from "react";
import tw from "twin.macro";
import LogoImage from "../../images/logo1.svg";
import LogoVN from "../../images/logo-vn.png";
import { FormattedMessage } from "react-intl";
import { Context } from "components/Wrapper";
const Container = tw.footer`relative bg-gray-200 grid grid-cols-4  gap-3 px-8 lg:py-8 py-8 mt-8`;
const Column = tw.div`md:flex md:justify-between sm:px-12 `;
const LinkList = tw.ul`text-sm font-normal`;
const LinkItem = tw.li`mt-0 pb-4`;
const LinkSocial = tw.a`border-b-2 border-transparent text-green-700 hocus:text-green-500 hocus:border-green-500 pb-1 transition duration-300`;
const Logo = tw.h3`lg:text-xl`;
const SubscribeContainer = tw.div`text-lg`;
const SubscribeText = tw.p`text-[0.95rem] text-gray-800 text-justify leading-9 md:mb-[1.25rem]`;

export default function FootNav() {
  const socialLink = [
    {
      title: <FormattedMessage id="headNav.home" defaultMessage="Home" />,
      link: "/",
    },
    {
      title: (
        <FormattedMessage id="headNav.about-us" defaultMessage="About Us" />
      ),
      link: "/about-us",
    },
    {
      title: (
        <FormattedMessage
          id="headNav.get-involved"
          defaultMessage="Get Involved"
        />
      ),
      link: "/get-involved",
    },
    {
      title: (
        <FormattedMessage
          id="headNav.understanding-ALS"
          defaultMessage="Understanding ALS"
        />
      ),
      link: "/understanding_ALS/ae9c363a-c164-4629-ba84-986f06513cc3",
    },
    {
      title: (
        <FormattedMessage
          id="headNav.navigating-ALS"
          defaultMessage="Navigating ALS"
        />
      ),
      link: "/navigating_ALS/f85441c4-8303-4716-b526-b6a06d14f37a",
    },
    {
      title: (
        <FormattedMessage
          id="headNav.healing-stories"
          defaultMessage="Healing Stories"
        />
      ),
      link: "/healing_stories/495a2b94-dcd9-4543-a2ef-5f24ae0f1cf8",
    },
    {
      title: <FormattedMessage id="headNav.donate" defaultMessage="donate" />,
      link: "/doantions",
    },
  ];
  const context = useContext(Context);
  return (
    <Container>
      <Column className="md:col-span-1 col-span-4">
        <Logo>
          {context.locale === 'en' ? <img
            src={LogoImage}
            alt="ALS Vietnam"
            className="w-[8rem] md:w-[12rem]"
          /> : <img
            src={LogoVN}
            alt="ALS Vietnam"
            className="w-[8rem] md:w-[12rem]"
          />}
          <br />

          <SubscribeText>
            Ho Chi Minh City, Vietnam <br />
            info.alsvietnam@gmail.com
          </SubscribeText>
          <div className="flex gap-4">

            <a href='https://www.facebook.com/profile.php?id=61552228445518' target="_blank">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 256 256"><path fill="#1877F2" d="M256 128C256 57.308 198.692 0 128 0C57.308 0 0 57.307 0 128c0 63.888 46.808 116.843 108 126.445V165H75.5v-37H108V99.8c0-32.08 19.11-49.8 48.347-49.8C170.352 50 185 52.5 185 52.5V84h-16.14C152.958 84 148 93.867 148 103.99V128h35.5l-5.675 37H148v89.445c61.192-9.602 108-62.556 108-126.445" /><path fill="#FFF" d="m177.825 165l5.675-37H148v-24.01C148 93.866 152.959 84 168.86 84H185V52.5S170.352 50 156.347 50C127.11 50 108 67.72 108 99.8V128H75.5v37H108v89.445A128.959 128.959 0 0 0 128 256a128.9 128.9 0 0 0 20-1.555V165h29.825" /></svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="#1d371d" d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95z"/></svg>
            </a>
          
           <a href='https://www.youtube.com/channel/UCEWen7ZdnKQ4PqDc4cNm7nA' target="_blank">
           {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 20"><path fill="#ff0000" d="M11.603 9.833L9.357 8.785C9.161 8.694 9 8.796 9 9.013v1.974c0 .217.161.319.357.228l2.245-1.048c.197-.092.197-.242.001-.334zM10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6s9.6-4.298 9.6-9.6S15.302.4 10 .4zm0 13.5c-4.914 0-5-.443-5-3.9s.086-3.9 5-3.9s5 .443 5 3.9s-.086 3.9-5 3.9z" /></svg> */}
           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 16 16"><path fill="#1d371d" d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104l.022.26l.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105l-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006l-.087-.004l-.171-.007l-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103l.003-.052l.008-.104l.022-.26l.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007l.172-.006l.086-.003l.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/></svg>
           </a>

          </div>
        </Logo>

      </Column>
      <Column className="md:mt-24 md:col-span-1 col-span-4">
        <LinkList>
          <h5 className="font-bold mb-6 text-base">
            {" "}
            <FormattedMessage id="footer.quick" />
          </h5>
          {socialLink ? (
            socialLink.map((link) => (
              <LinkItem key={link.title}>
                <LinkSocial href={link.link}>{link.title}</LinkSocial>
              </LinkItem>
            ))
          ) : (
            <a>null</a>
          )}
        </LinkList>
      </Column>
      <Column className="col-span-4 md:col-span-2 md:mt-24 md:-ml-32">
        <SubscribeContainer>
          <h5 className="font-bold text-base">
            {" "}
            <FormattedMessage
              id="home.Disclaimer.title"
              defaultMessage="Miễn trừ trách nhiệm"
            />
          </h5>
          <SubscribeText>
            <FormattedMessage
              id="home.Disclaimer"
              defaultMessage="Mong muốn lớn nhất của chúng tôi là nâng cao kiến thức 
            cộng đồng cũng như giúp bệnh nhân ALS và các bệnh thoái hóa thần kinh khác 
            tại Việt Nam có thêm thông tin hữu ích, hy vọng và có cái nhìn lạc quan hơn trong hành trình chữa lành. 
            Chúng tôi nỗ lực để cung cấp thông tin khách quan và chính xác để giúp họ có cuộc sống tốt đẹp hơn. 
            Chúng tôi kết nối và học hỏi từ những người chiến thắng ALS, giới chuyên gia cũng như đối chiếu các nghiên cứu 
            liên quan để cung cấp bằng chứng rằng các triệu chứng ALS có thể được đảo ngược hoặc làm chậm sự tiến triển của nó.
             Chúng tôi khuyến khích độc giả tìm hiểu thêm những giải pháp mới, các ý kiến từ nhiều nguồn tư liệu khác nhau và cởi mở 
             hơn với những giải pháp đúng đắn để quá trình chữa trị đạt được hiệu quả tốt nhất. 
             Tuy nhiên, chúng tôi không có thẩm quyền để chẩn đoán hay chữa trị căn bệnh này. 
             Vì vậy, bệnh nhân và người nhà nên tham khảo ý kiến của chuyên gia trước khi thực hiện một phương pháp điều trị mới."
            />
          </SubscribeText>
        </SubscribeContainer>
      </Column>
    </Container>
  );
}
